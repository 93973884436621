/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { clsx } from 'clsx';
import { h } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';

import type { Values } from '../../../types';

import { addCustomerProperties } from '../../../api';
import { submitCustomerOptIn } from '../../../biz';
import { assertUnreachable, noop, promiseTry, run } from '../../../lib';
import { Style } from '../../style';
import { ConsentPopupContext } from '../context';

import type { Email, PhoneNumber } from '@onetext/api';

export const POPUP_LAYOUT = {
    FULLSCREEN: 'fullscreen',
    MODAL:      'modal'
} as const;

type ConsentPopupProps = {
    children ?: ComponentChildren,
    layout ?: Values<typeof POPUP_LAYOUT>,
};

export const ConsentPopup = ({
    children,
    layout = POPUP_LAYOUT.MODAL
} : ConsentPopupProps) : VNode | null => {
    const [ email, setEmail ] = useState<Email>();
    const [ phone, setPhone ] = useState<PhoneNumber>();

    const [ properties, setProperties ] = useState<Record<string, string>>({});

    const [ pageIDs, setPageIDs ] = useState<Array<number>>([]);
    const [ activePageID, setActivePageID ] = useState<number>();
    const [ isOpen, setIsOpen ] = useState<boolean>(true);

    const registerPage = useCallback((pageID : number) => {
        setActivePageID(existingPageID => {
            return existingPageID ?? pageID;
        });

        setPageIDs(currentPageIDs => {
            return [
                ...currentPageIDs,
                pageID
            ];
        });

        return () => {
            setPageIDs(currentPageIDs => {
                return currentPageIDs.filter(id => id !== pageID);
            });
        };
    }, [ activePageID, setActivePageID, setPageIDs ]);

    const advancePage = useCallback(() => {
        if (!activePageID) {
            throw new Error('No active page');
        }

        const currentPageIndex = pageIDs.indexOf(activePageID);

        if (currentPageIndex === -1) {
            throw new Error('Can not find current page');
        }

        const nextPageIndex = currentPageIndex + 1;

        if (nextPageIndex >= pageIDs.length) {
            setIsOpen(false);
            return;
        }

        setActivePageID(pageIDs[nextPageIndex]);
    }, [ activePageID, pageIDs, setActivePageID, setIsOpen ]);

    const submitPage = useCallback(() => {
        return promiseTry(() => {
            if (phone && Object.keys(properties).length) {
                void addCustomerProperties({
                    phone,
                    properties
                }).catch(noop);
            }

            if (email || phone) {
                void submitCustomerOptIn({
                    email,
                    phone
                }).catch(noop);
            }

            advancePage();
        });
    }, [ email, phone, properties, advancePage ]);

    const popupContext = useMemo(() => {
        return {
            email,
            setEmail,
            phone,
            setPhone,
            properties,
            setProperties,
            registerPage,
            activePageID,
            submitPage,
            isOpen,
            setIsOpen
        };
    }, [ email, setEmail, phone, setPhone, registerPage, activePageID, submitPage, isOpen, setIsOpen ]);

    if (!isOpen) {
        return null;
    }

    return (
        <ConsentPopupContext.Provider value={ popupContext }>
            <Style>
                <form onSubmit={ event => event.preventDefault() }>
                    <div class={ `w-full h-full top-0 left-0 fixed bg-slate-500 bg-opacity-50 z-50` }>
                        <div
                            class={
                                clsx(
                                    run(() => {
                                        switch (layout) {
                                            case POPUP_LAYOUT.FULLSCREEN:
                                                return `w-full h-full top-0 left-0 fixed`;
                                            case POPUP_LAYOUT.MODAL:
                                                return `top-[50%] left-[50%] fixed -translate-x-1/2 -translate-y-1/2 px-32 py-24 min-w-[40vw] min-h-[40vh]`;
                                            default:
                                                throw assertUnreachable(layout);
                                        }
                                    }),
                                        `bg-white`,
                                        `flex flex-col justify-center items-center`,
                                        `font-sans text-slate-900`
                                )
                            }
                        >
                            { children }
                        </div>
                    </div>
                </form>
            </Style>
        </ConsentPopupContext.Provider>
    );
};
