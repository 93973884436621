/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';

import { useConsentPopupContext, useConsentPopupPageContext } from '../context';

import { ConsentPopupTextField } from './text-field';

type ConsentPopupPhoneFieldProps = {
    autofocus ?: boolean,
    placeholder ?: string,
    submitOnAutoComplete ?: boolean,
};

export const ConsentPopupPhoneField = ({
    autofocus,
    placeholder = 'Phone Number',
    submitOnAutoComplete = true
} : ConsentPopupPhoneFieldProps) : VNode => {
    const {
        setPhone,
        phone
    } = useConsentPopupContext();

    const {
        submit
    } = useConsentPopupPageContext();

    return (
        <ConsentPopupTextField
            type={ 'tel' }
            id={ 'phone' }
            autocomplete={ 'tel' }
            autofocus={ autofocus }
            placeholder={ placeholder }
            defaultValue={ phone }
            onValue={ setPhone }
            onAutoComplete={
                () => {
                    if (submitOnAutoComplete) {
                        void submit();
                    }
                }
            }
        />
    );
};
