/** @jsx h */
import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { twMerge as tw } from 'tailwind-merge';

import { openKeyboard } from '../../../lib';
import { ConsentButtonContext, ConsentPopupPageContext, useConsentPopupContext } from '../context';

let globalPageID = 0;

type ConsentPopupPageProps = {
    children : ComponentChildren,
    className ?: string,
    openKeyboardOnSubmit ?: boolean,
};

export const ConsentPopupPage = ({
    children,
    className = undefined,
    openKeyboardOnSubmit = false
} : ConsentPopupPageProps) : VNode | null => {
    const [ pageID ] = useState(() => {
        globalPageID += 1;
        return globalPageID;
    });

    const {
        activePageID,
        registerPage,
        submitPage
    } = useConsentPopupContext();

    useEffect(() => {
        return registerPage(pageID);
    }, [ pageID, registerPage ]);

    if (activePageID !== pageID) {
        return null;
    }

    const submit = useCallback(() => {
        if (openKeyboardOnSubmit) {
            openKeyboard();
        }

        return submitPage();
    }, [ submitPage, openKeyboardOnSubmit ]);

    const pageContext = useMemo(() => {
        return {
            submit
        };
    }, [ submitPage ]);

    const buttonContext = useMemo(() => {
        return {
            submit
        };
    }, [ submitPage ]);

    return (
        <ConsentPopupPageContext.Provider value={ pageContext }>
            <ConsentButtonContext.Provider value={ buttonContext }>
                <div class={ tw(className) }>
                    { children }
                </div>
            </ConsentButtonContext.Provider>
        </ConsentPopupPageContext.Provider>
    );
};
