/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useState } from 'preact/hooks';
import { twMerge as tw } from 'tailwind-merge';

import { LoadingDots } from '../../loading-dots';
import { useConsentButtonContext } from '../context';

type ConsentPopupButtonProps = {
    children : ComponentChildren,
    className ?: string,
};

export const ConsentPopupButton = ({
    children,
    className = undefined
} : ConsentPopupButtonProps) : VNode => {
    const {
        submit
    } = useConsentButtonContext();

    const [ loading, setLoading ] = useState(false);

    const onClick = async (event : Event) : Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);
        await submit();
        setLoading(false);
    };

    return (
        <button
            type={ 'button' }
            onClick={ event => void onClick(event) }
            className={
                tw(
                    `w-full py-2 min-w-[200px] text-center text-white bg-slate-900 hover:bg-slate-700 focus:bg-slate-700 focus:outline-none cursor-pointer`,
                    className
                )
            }
        >
            {
                loading
                    ? <LoadingDots />
                    : children
            }
        </button>
    );
};
