/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';

import {
    ConsentButtonContext, ConsentQuizContext, useConsentPopupContext, useConsentPopupPageContext, useConsentQuizContext
} from '../context';

type ConsentPopupQuizProps = {
    children ?: ComponentChildren,
    propertyName : string,
};

export const ConsentPopupQuiz = ({
    children,
    propertyName
} : ConsentPopupQuizProps) : VNode => {
    const {
        setProperties
    } = useConsentPopupContext();

    const {
        submit
    } = useConsentPopupPageContext();

    const submitQuizOption = useCallback(async () => {
        setProperties(existingProperties => {
            return {
                ...existingProperties,
                [ propertyName ]: 'foo'
            };
        });

        await submit();
    }, [ propertyName, setProperties ]);

    const quizContext = useMemo(() => {
        return {
            submitQuizOption
        };
    }, [ submitQuizOption ]);

    return (
        <ConsentQuizContext.Provider value={ quizContext }>
            { children }
        </ConsentQuizContext.Provider>
    );
};

type ConsentPopupQuizOptionProps = {
    propertyValue : string,
    children ?: ComponentChildren,
};

export const ConsentPopupQuizOption = ({
    propertyValue,
    children
} : ConsentPopupQuizOptionProps) : VNode => {
    const {
        submitQuizOption
    } = useConsentQuizContext();

    const buttonContext = useMemo(() => {
        return {
            submit: () => {
                return submitQuizOption({
                    propertyValue
                });
            }
        };
    }, [ submitQuizOption ]);

    return (
        <ConsentButtonContext.Provider value={ buttonContext }>
            { children }
        </ConsentButtonContext.Provider>
    );
};
