/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';

type StyleProps = {
    children : ComponentChildren,
};

export const Style = ({ children } : StyleProps) : VNode | null => {
    return (
        <div class={ 'ot-sdk' }>
            { children }
        </div>
    );
};
