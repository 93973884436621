/** @jsx h */
import type { VNode } from 'preact';

import { h } from 'preact';

import { useConsentPopupContext } from '../context';

export const ConsentPopupCloseButton = () : VNode => {
    const { setIsOpen } = useConsentPopupContext();

    const handleClick = () : void => {
        setIsOpen(false);
    };

    return (
        <button
            onClick={ handleClick }
            type={ 'button' }
            class={ 'fade-in-up' }
            style={
                {
                    position:       'fixed',
                    top:            20,
                    right:          20,
                    width:          24,
                    height:         24,
                    background:     'none',
                    border:         'none',
                    cursor:         'pointer',
                    animationDelay: '0.75s'
                }
            }
            aria-label={ 'Close popup' }
        >
            <svg
                width={ '25' }
                height={ '25' }
                viewBox={ '0 0 25 25' }
                fill={ 'none' }
                xmlns={ 'http://www.w3.org/2000/svg' }>
                <path
                    d={ 'M2.97266 2.97168L22.1799 22.1789' }
                    stroke={ 'black' }
                    stroke-width={ '1.12984' } />
                <path
                    d={ 'M22.1799 2.97168L2.97266 22.1789' }
                    stroke={ 'black' }
                    stroke-width={ '1.12984' } />
            </svg>
        </button>
    );
};
