/** @jsx h */
import type { VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';

type ConsentPopupTextFieldProps<Type extends string> = {
    type ?: string,
    id ?: string,
    autocomplete ?: string,
    placeholder ?: string,
    autofocus ?: boolean,
    defaultValue ?: Type,
    onValue ?: (value : Type) => void,
    onAutoComplete ?: () => void,
};

export const ConsentPopupTextField = <Type extends string>({
    type,
    id,
    autocomplete,
    placeholder,
    autofocus = false,
    defaultValue,
    onAutoComplete,
    onValue
} : ConsentPopupTextFieldProps<Type>) : VNode => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [ currentValue, setCurrentValue ] = useState<string | undefined>(defaultValue);
    const [ priorCurrentValue, setPriorCurrentValue ] = useState<string | undefined>(defaultValue);

    useEffect(() => {
        setPriorCurrentValue(currentValue);

        if (
            priorCurrentValue === undefined &&
            currentValue &&
            currentValue.length >= 3 &&
            autocomplete &&
            onAutoComplete
        ) {
            onAutoComplete();
        }
    }, [ currentValue, priorCurrentValue, autocomplete, onAutoComplete ]);

    const focus = useCallback(() => {
        const input = inputRef.current;

        if (input) {
            // if not focused, focus
            if (!document.activeElement?.isSameNode(input)) {
                input.focus({
                    preventScroll: true
                });

                const rect = input.getBoundingClientRect();
                const scrollTo = rect.top - 200;

                window.scrollTo({
                    top:      scrollTo,
                    behavior: 'smooth'
                });
            }
        }
    }, [ inputRef.current ]);

    useEffect(() => {
        focus();
        setTimeout(focus, 10);
    }, [ autofocus, inputRef.current ]);

    const updateCurrentValue = (value : string) : void => {
        setCurrentValue(value);

        if (onValue) {
            onValue(value as Type);
        }
    };

    return (
        <input
            id={ id }
            autocomplete={ autocomplete }
            ref={ inputRef }
            type={ type }
            placeholder={ placeholder }
            class={ `border border-slate-300 rounded py-2 px-2.5 min-w-[200px] w-full h-12 focus:outline-none` }
            value={ currentValue }
            onInput={ event => updateCurrentValue(event.currentTarget.value) }
        />
    );
};
