import { QUERY_PARAM, SDK_ATTRIBUTE, SDK_QUERY } from '../constants';

import { getURLQueryParam } from './dom';
import { getCurrentScript, tokenToEnv } from './util';

import { type ENV, NODE_ENV } from '@onetext/api';

export const getSDKScript = () : HTMLScriptElement => {
    return getCurrentScript();
};

export const getSDKUrl = () : string => {
    const script = getSDKScript();

    return script.getAttribute(SDK_ATTRIBUTE.API_DOMAIN) ?? script.src;
};

export const getSDKDomain = () : string => {
    const url = new URL(getSDKUrl());
    return `${ url.protocol }//${ url.host }`;
};

export const getSDKAttribute = (name : string) : string | undefined => {
    const value = getSDKScript().getAttribute(name);
    return value ?? undefined;
};

export const getSDKScriptQueryParams = () : URLSearchParams => {
    const scriptSrc = getSDKAttribute('src');

    if (!scriptSrc?.match(/^https?:\/\//)) {
        return new URLSearchParams();
    }

    const url = new URL(scriptSrc);
    return url.searchParams;
};

export const getSDKQueryParam = (name : string) : string | undefined => {
    const queryParams = getSDKScriptQueryParams();
    return queryParams.get(name) ?? undefined;
};

export const getSDKAccountToken = () : string | undefined => {
    return getSDKAttribute(SDK_ATTRIBUTE.ACCOUNT_TOKEN) ?? getSDKQueryParam(SDK_QUERY.ACCOUNT_TOKEN);
};

export const getSDKClientID = () : string | undefined => {
    return getSDKAttribute(SDK_ATTRIBUTE.CLIENT_ID) ?? getSDKQueryParam(SDK_QUERY.CLIENT_ID);
};

export const getSDKEnv = () : ENV => {
    const sdkEnv = getSDKAttribute(SDK_ATTRIBUTE.ENV);

    if (sdkEnv) {
        return sdkEnv as ENV;
    }

    const sdkAccountToken = getSDKAccountToken();

    if (sdkAccountToken) {
        return tokenToEnv(sdkAccountToken);
    }

    const sdkClientID = getSDKClientID();

    if (sdkClientID) {
        return tokenToEnv(sdkClientID);
    }

    throw new Error(`Can not determine SDK environment`);
};

type SDKScriptData = {
    src : string,
    attributes : Record<string, string | undefined>,
};

export const getSDKScriptData = () : SDKScriptData => {
    return {
        src:        getSDKUrl(),
        attributes: {
            [SDK_ATTRIBUTE.ACCOUNT_TOKEN]: getSDKAccountToken(),
            [SDK_ATTRIBUTE.CLIENT_ID]:     getSDKClientID()
        }
    };
};

export const isDevelopment = () : boolean => {
    return process.env.NODE_ENV === NODE_ENV.DEVELOPMENT;
};

export const isPreviewMode = () : boolean => {
    return Boolean(
        isDevelopment() ||
        getURLQueryParam(QUERY_PARAM.PREVIEW)
    );
};
