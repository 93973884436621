import { initializeCustomer } from './biz';
import { initializeIntegrations } from './integrations';
import { promiseTry } from './lib';

export * from './index';

void promiseTry(async () => {
    await Promise.all([
        initializeIntegrations(),
        initializeCustomer()
    ]);
});
