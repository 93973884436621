import { submitOptIn } from '../../api';
import { noop } from '../../lib';
import { identifyCustomer } from '../customer';

import type { JSONObject, PhoneNumber } from '@onetext/api';

type SubmitOptInOptions = {
    phone ?: PhoneNumber,
    customFlowParameters ?: JSONObject,
    email ?: string,
};

export const submitCustomerOptIn = ({
    phone,
    email,
    customFlowParameters
} : SubmitOptInOptions) : Promise<void> => {
    return Promise.all([
        phone
            ? identifyCustomer({
                phone
            })
            : undefined,

        submitOptIn({
            phone,
            email,
            customFlowParameters,
            requestTwoFactorCode: false,
            invokeCoreFlow:       true
        })
    ]).then(noop);
};
