import { getSDKAccountToken, getSDKClientID, getSDKDomain, promiseTry } from '../lib';

enum HTTP_METHOD {
    GET = 'GET',
    POST = 'POST'
}

type CallOneTextAPIOptions = {
    method ?: HTTP_METHOD,
    path : string,
    body ?: unknown,
};

export type CallOneTextAPIResult<ResponseBodyType> = {
    body : ResponseBodyType,
};

export const callOneTextAPI = <ResponseBodyType>({
    method = HTTP_METHOD.POST,
    path,
    body
} : CallOneTextAPIOptions) : Promise<CallOneTextAPIResult<ResponseBodyType>> => {
    const username = getSDKAccountToken() ?? getSDKClientID();
    return fetch(`${ getSDKDomain() }/api/${ path }?env=production`, {
        method,
        headers: {
            'content-type':  'application/json',
            'authorization': username
                ? `Basic ${ btoa(`${ username }:`) }`
                : ''
        },
        body: JSON.stringify(body)
    }).then(res => {
        if (!res.ok) {
            throw new Error(`One Text API call to ${ path } failed with status ${ res.status }`);
        }

        return res.json().then(json => {
            return {
                body: json as ResponseBodyType
            };
        });
    });
};

type ConstructOneTextURLOptions = {
    path : string,
    query ?: Record<string, string>,
};

export const constructOneTextURL = ({
    path,
    query
} : ConstructOneTextURLOptions) : string => {
    const queryString = new URLSearchParams(query).toString();
    return `${ getSDKDomain() }/${ path }?${ queryString }`;
};

type RedirectInPopupOptions = {
    url : string,
};

export const redirectInPopup = ({
    url
} : RedirectInPopupOptions) : Promise<void> => {
    return promiseTry(() => {
        window.open(url, '_blank');
    });
};
