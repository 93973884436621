import { coreFlowTypes, getCustomer, launchCoreFlow, launchCustomFlow, submitCustomerOptIn } from './biz';
import {
    ConsentMultiPopup, ConsentPopup, ConsentPopupButton, ConsentPopupColumn, ConsentPopupColumns,
    ConsentPopupEmailField, ConsentPopupPage, ConsentPopupPhoneField, ConsentPopupQuiz, ConsentPopupQuizOption,
    ConsentPopupRow, ConsentPopupSpinner, ConsentPopupTerms, ConsentPopupVerticalSpacer, POPUP_LAYOUT,
    SPINNER_ANIMATION
} from './components';
import { DEFAULT_POPUP_ID } from './constants';
import { clsx, element, render, useEffect, useState } from './jsx';
import { deprecatedGetBrandSDKPopupID, isDevelopment, isPreviewMode } from './lib';
import { BUTTON_LEVEL, BUTTON_SIZE } from './types';

export const customer = {
    get: getCustomer
};

export const flow = {
    core: {
        types: coreFlowTypes,
        start: launchCoreFlow
    },
    custom: {
        start: launchCustomFlow
    }
};

export const optin = {
    submit: submitCustomerOptIn
};

export const jsx = {
    element,
    render,
    useState,
    useEffect,
    clsx
};

export const component = {
    consent: {
        popup: {
            MultiPopup:     ConsentMultiPopup,
            Popup:          ConsentPopup,
            Page:           ConsentPopupPage,
            Button:         ConsentPopupButton,
            EmailField:     ConsentPopupEmailField,
            PhoneField:     ConsentPopupPhoneField,
            Quiz:           ConsentPopupQuiz,
            QuizOption:     ConsentPopupQuizOption,
            Row:            ConsentPopupRow,
            Columns:        ConsentPopupColumns,
            Column:         ConsentPopupColumn,
            VerticalSpacer: ConsentPopupVerticalSpacer,
            Spinner:        ConsentPopupSpinner,
            Terms:          ConsentPopupTerms,

            BUTTON_LEVEL,
            BUTTON_SIZE,
            POPUP_LAYOUT,
            SPINNER_ANIMATION
        }
    }
};

export const sdk = {
    isDevelopment,
    isPreviewMode
};

export const popup = {
    DEFAULT_POPUP_ID,
    id: deprecatedGetBrandSDKPopupID
};

export { events } from './biz';
