export enum SDK_ATTRIBUTE {
    ENV = 'data-env',
    ACCOUNT_TOKEN = 'data-account-token',
    CLIENT_ID = 'data-client-id',
    API_DOMAIN = 'data-api-domain'
}

export enum SDK_QUERY {
    ACCOUNT_TOKEN = 'onetext-account-token',
    CLIENT_ID = 'onetext-client'
}

export enum QUERY_PARAM {
    ONETEXT_ACCOUNT_TOKEN_LEGACY = 'onetext-token',
    ONETEXT_ACCOUNT_TOKEN = 'onetext-account-token',
    KLAVIYO_IDENTIFIER = 'nb_klid',
    PREVIEW = 'onetext-preview'
}

export enum LOCALSTORAGE_KEY {
    ONETEXT_ACCOUNT_TOKEN = 'onetext-account-token'
}
