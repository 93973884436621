// Set up preact context

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

type ConsentButtonContextType = {
    submit : () => Promise<void> | void,
};

export const ConsentButtonContext = createContext<ConsentButtonContextType | undefined>(undefined);

export const useConsentButtonContext = () : ConsentButtonContextType => {
    const context = useContext(ConsentButtonContext);

    if (!context) {
        throw new Error('Can not find consent button context');
    }

    return context;
};
